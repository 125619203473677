html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.max-w-inherit {
  max-width: inherit;
}

.App-link {
  color: #61dafb;
}

.break-words {
  word-break: break-word;
  overflow-wrap: break-word;
}

.fill-available {
  width: -webkit-fill-available;
}

input,
select,
textarea {
  border: 1px solid #cbd5e1;
  -webkit-border-radius: 0.375rem;
  border-radius: 0.375rem;
}

input[type="checkbox"] {
  appearance: none;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  border: 1px solid #cbd5e1;
  /* Adjust border thickness */
  border-radius: 0.5rem;
  /* Circular border */
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

input[type="radio"] {
  appearance: none;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  border: 1px solid #cbd5e1;
  /* Adjust border thickness */
  border-radius: 50%;
  /* Circular border */
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: white;
  /* Tailwind's blue-500 */
  border-color: #3b82f6;
  /* Blue border when checked */
}

input[type="checkbox"]:checked::after {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #3b82f6;
  border-radius: 0.2rem;
  /* Square checkmark inside the circle */
  position: absolute;
}

input[type="radio"]:checked::after {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #3b82f6;
  border-radius: 50%;
  /* Circle inside the radio button */
  position: absolute;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}